/* eslint-disable react/destructuring-assignment */
import { useCallback } from "react";

import ContestCard from './ContestCard';
import type { ContestCardProps } from './types';

function ConnectedContestCard(props: ContestCardProps) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      props.onClick?.(e);
      if (e.isDefaultPrevented() && props.isStatic) {
        // Keeping this return here for now
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    [props]
  );

  return <ContestCard {...props} onClick={handleClick} />;
}

export default ConnectedContestCard;
