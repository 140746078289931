import Cookies from 'js-cookie';
import { useMemo } from 'react';

import { jwtDecodeSafe } from '~/utils/jwt';

import { CognitoGroups } from './consts';

type AuthTokenJwtType = {
  sub: string;
  role: string;
  email_verified: boolean;
  roles: string[];
  'cognito:groups'?: string[];
  username: string;
}

export const getIsUserAdmin = (accessToken: string = Cookies.get('accessToken')) => {
  if (!accessToken) {
    return false;
  }

  const jwt = jwtDecodeSafe<AuthTokenJwtType>(accessToken);
  const groups = jwt?.['cognito:groups'];
  const roles = jwt?.roles;

  return groups?.includes(CognitoGroups.SPLADMINS) || roles?.includes('admin');
};

const useIsUserAdmin = () => {
  const isUserAdmin = useMemo(() => getIsUserAdmin(), []);

  return isUserAdmin;
};

export default useIsUserAdmin;
